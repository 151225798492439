import React, { useContext, useEffect, useState } from "react"
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import { AiFillAlert, AiOutlineCheckCircle, AiOutlineFileSearch } from 'react-icons/ai'
import { BiExport } from 'react-icons/bi'
import { BsCheckAll, BsFillPersonFill, BsFillPlusCircleFill } from 'react-icons/bs'
import { FaSortAmountDownAlt } from 'react-icons/fa'
import { HiOutlineReceiptTax } from 'react-icons/hi'
import { MdCloud } from 'react-icons/md'
import { VscAccount } from 'react-icons/vsc'
import { useNavigate } from "react-router-dom"
import Footer from "../components/footer"
import Navegacao from "../components/navbar"
import { GlobalContext } from '../contexts/global'
import LogoArquivo from '../images/logo.svg'

function Home() {

    const { usuario, tipoAcesso, licenca, conexao, permissao } = useContext(GlobalContext)
    const [loading, setLoading] = useState(false)
    const [msgLoading, setMsgLoading] = useState('')

    const Navigate = useNavigate()

    const [telaMsg, setTelaMsg] = useState('')
    const [telaTitulo, setTelaTitulo] = useState('')

    const [telaAlerta, setTelaAlerta] = useState('')

    const [estatisticas, setEstatisticas] = useState()

    const authToken = localStorage.getItem('auth-token')

    const [total, setTotal] = useState('0')
    const [cliente, setCliente] = useState('0')
    const [escritorio, setEscritorio] = useState('0')
    const [finalizadas, setFinalizadas] = useState('0')

    useEffect(() => {

        if (authToken) {
            Navigate('/departamento-pessoal')
        }
        console.log('Chegou no useEffect da Home')

        if (tipoAcesso == 'OPERADOR') {
            // ObterEstatisticasSolicitacoes(localStorage.getItem('email'))
        }

    }, [tipoAcesso])

    function ExibirAlerta(titulo, mensagem) {
        setTelaMsg(mensagem)
        setTelaTitulo(titulo)
        setTelaAlerta(true)
    }


    // async function ObterEstatisticasSolicitacoes(usuarioInformado) {
    //     // setMsgLoading('Obtendo Estatísticas de Atendimento')
    //     // setLoading(true)

    //     const url = conexao.endereco + '/ObtemEstatisticasSolicitacoes?email=' + usuarioInformado

    //     let requisicao = new XMLHttpRequest()

    //     requisicao.timeout = 8000
    //     requisicao.open('GET', url, true)
    //     requisicao.setRequestHeader('Content-Type', 'application/json')
    //     requisicao.setRequestHeader('Authorization', conexao.autenticacao)
    //     requisicao.send()

    //     requisicao.ontimeout = function () {
    //         console.log('TimeOut na Requisição: ObtemEstatisticasSolicitacoes')
    //         // setLoading(false)
    //         ExibirAlerta('Tempo Limite Excedido', 'Requisição: ObtemEstatisticasSolicitacoes')
    //     }

    //     requisicao.onerror = function () {
    //         console.log('Erro na Requisição: ObtemEstatisticasSolicitacoes: ' + this.response)
    //         // setLoading(false)
    //         ExibirAlerta('Falha na Requisição', JSON.stringify(this.response))
    //     }

    //     requisicao.onload = function () {
    //         // setLoading(false)
    //         console.log('RESPONSE: ' + this.response)
    //         if (requisicao.status === 200) {
    //             let resposta = JSON.parse(this.response)
    //             setTotal(resposta.TOTAL)
    //             setCliente(resposta.PENDENTE_CLIENTE)
    //             setEscritorio(resposta.PENDENTE_OPERADOR)
    //             setFinalizadas(resposta.FINALIZADAS)

    //         } else {
    //             alert('Falha ao Obter Dados')
    //         }

    //     }
    // }

    return (
        <>

            <Navegacao />
            {usuario.email &&
                <>

                    <Container style={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%', flexDirection: 'column', marginBottom: 80 }}>

                        <Row sm={12} lg={12} style={{ paddingTop: 20, paddingLeft: 25, paddingRight: 25 }}>
                            <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                <h3>Olá, {usuario.nome}</h3>
                            </Col>
                            <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>
                                <Button
                                    style={{ backgroundColor: '#E30613', border: 0 }}
                                    size="sm"
                                    onClick={() => {
                                        alert(tipoAcesso)
                                    }}
                                >
                                    Obter Ajuda
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ paddingLeft: 40, paddingRight: 40 }}>
                            <hr />
                        </Row>
                        <Row style={{ paddingLeft: 25, paddingRight: 25, marginBottom: 20 }}>
                            <Col>
                                <div style={{
                                    backgroundColor: '#fff4e5',
                                    border: '1px solid #ffd089',
                                    borderRadius: '8px',
                                    padding: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
                                }}>
                                    <div>
                                        <h5 style={{ color: '#de004a', marginBottom: '10px' }}>🎉 Nova versão do Portal do Cliente disponível!</h5>
                                        <p style={{ marginBottom: '0', fontSize: '14px', color: '#6b6b6b' }}>
                                            Experimente a nova versão do portal com melhorias e novos recursos.
                                        </p>
                                    </div>
                                    <Button
                                        style={{
                                            backgroundColor: '#E30613',
                                            border: 'none',
                                            color: 'white',
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            fontWeight: 'bold'
                                        }}
                                        onClick={() => {
                                            window.open('https://portaldocliente.daccta.com.br', '_blank');
                                        }}
                                    >
                                        Acessar Nova Versão
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                        {tipoAcesso === 'USUARIO' &&

                            <div style={{ paddingLeft: 25, paddingRight: 25 }} > {/* DIV DOS CARDS */}

                                {/* <Row>
                                    <Col lg={6} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <HiOutlineReceiptTax size="32" color="#E30613" />   Impostos   <strong style={{ color: 'grey', fontSize: 14 }}>Maio de 2022</strong>
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', fontSize: 36, marginTop: 20 }}>
                                                    R$ 1.042,46
                                                </Card.Title>
                                                <Card.Subtitle style={{ display: 'flex', justifyContent: 'flex-end', color: 'grey', fontSize: 14, marginBottom: 20 }}>
                                                    Aguardando Pagamento
                                                </Card.Subtitle>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#E30613', borderColor: '#E30613' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            Navigate(`/impostos/guias-fiscais/${new Date().toLocaleDateString('pt-BR', { month: 'long' })}/${new Date().toLocaleDateString('pt-BR', { year: 'numeric' })}`)
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={6} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <FaHistory size="24" color="#E30613" />   Rotinas   <strong style={{ color: 'grey', fontSize: 14 }}>Maio de 2022</strong>
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'flex-start', color: '#E30614', marginTop: 20, marginBottom: 22 }}>
                                                    Próxima Rotina:
                                                </Card.Title>
                                                <Card.Subtitle style={{ display: 'flex', justifyContent: 'flex-start', color: 'grey', fontSize: 18, marginBottom: 20 }}>
                                                    Dia 25 - Envio dos Extratos Bancários
                                                </Card.Subtitle>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#E30613', borderColor: '#E30613' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            alert('Detalhar Impostos')
                                                        }}
                                                    >
                                                        Ver Todas as Rotinas
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>

                                </Row> */}

                                <Row style={{ marginBottom: 60 }}>
                                    <Col lg={12} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%' }}>
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 24 }}>
                                                    <BsCheckAll size="32" color="#E30613" />   Acesso Rápido
                                                </Card.Header>
                                                <Row style={{ padding: 10 }}>
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {
                                                        Navigate(`/impostos/guias-fiscais/${new Date().toLocaleDateString('pt-BR', { month: 'long' })}/${new Date().toLocaleDateString('pt-BR', { year: 'numeric' })}`)
                                                    }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <HiOutlineReceiptTax color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Consultar Impostos</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    {/* {usuario.email === 'testedp@arquivocontabil.com.br' && */}
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {
                                                        Navigate(`/departamento-pessoal/consultar-folha/${new Date().toLocaleDateString('pt-BR', { month: 'long' })}/${new Date().toLocaleDateString('pt-BR', { year: 'numeric' })}`)
                                                    }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AiOutlineFileSearch color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Consultar Folha Pgto</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    {/* } */}
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {
                                                        Navigate('/movimento-bancario/importar-extrato-ofx')
                                                    }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <BiExport color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Enviar Extrato OFX</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    {/* {usuario.email === 'testedp@arquivocontabil.com.br' && */}
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {
                                                        Navigate('/departamento-pessoal/admissoes')
                                                    }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <BsFillPlusCircleFill color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Admissões Funcionários</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    {/* } */}
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {
                                                        Navigate('/arquivos')
                                                    }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <MdCloud color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Drive de Arquivos</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    {/* {usuario.email === 'testedp@arquivocontabil.com.br' && */}
                                                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }} onClick={() => {
                                                        Navigate('/departamento-pessoal/')
                                                    }}>
                                                        <Card className="card-home">
                                                            <Card.Body>
                                                                <Card.Header style={{ height: '60px', backgroundColor: 'white', border: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AiFillAlert color="#E30614" size="36" />
                                                                </Card.Header>
                                                                <Card.Title style={{ textAlign: 'center', fontSize: 12 }}>Minhas Solicitações</Card.Title>
                                                            </Card.Body>

                                                        </Card>
                                                    </Col>
                                                    {/* } */}



                                                </Row>


                                            </Card.Body>
                                        </Card>
                                    </Col>


                                </Row>

                            </div>
                        }

                        {tipoAcesso === 'OPERADOR' &&



                            <div style={{ paddingLeft: 25, paddingRight: 25 }} > {/* DIV DOS CARDS */}

                                <Row sm={12} lg={12} style={{ paddingTop: 30 }} >
                                    <Col sm={12} lg={7} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 5 }}>
                                        <h5>Solicitações</h5>
                                        {/* <strong style={{ color: 'grey', fontSize: 14, marginLeft: 15 }}>Junho de 2022</strong> */}
                                    </Col>
                                    {/* <Col sm={12} lg={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 5 }}>

                                        <AiOutlineCalendar
                                            size="32" color="#E30613"
                                            style={{ cursor: 'pointer' }}
                                            alt="Vish"
                                            onClick={() => {
                                                alert(JSON.stringify(estatisticas))
                                            }}
                                        />

                                    </Col> */}
                                    <Row style={{ paddingLeft: 25, paddingRight: 5 }}>
                                        <hr />
                                    </Row>
                                </Row>

                                <Row>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: 'black', fontSize: 20 }}>
                                                    <FaSortAmountDownAlt size="32" color="black" /> Recebidas
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: 'black', fontSize: 72, marginTop: 20 }}>
                                                    {total}
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: 'black', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            Navigate('/adm/solicitacoes/minhas-solicitacoes', { state: { statusRecebido: 'TODOS' } })
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#20699F', fontSize: 20 }}>
                                                    <BsFillPersonFill size="32" color="#20699F" /> Cliente
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: '#20699F', fontSize: 72, marginTop: 20 }}>
                                                    {cliente}
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#20699F', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#20699F', borderColor: '#20699F' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            Navigate('/adm/solicitacoes/minhas-solicitacoes', { state: { statusRecebido: 'PENDENTE CLIENTE' } })
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#E30613', fontSize: 20 }}>
                                                    <VscAccount size="32" color="#E30613" /> Escritório
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: '#E30614', fontSize: 72, marginTop: 20 }}>
                                                    {escritorio}
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#E30614', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#E30613', borderColor: '#E30613' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            Navigate('/adm/solicitacoes/minhas-solicitacoes', { state: { statusRecebido: 'PENDENTE OPERADOR' } })
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={3} sm={12} style={{ padding: 15 }}>
                                        <Card style={{ width: '100%', minHeight: 260 }} className="card-solicitacao">
                                            <Card.Body>
                                                <Card.Header style={{ backgroundColor: 'white', color: '#007D00', fontSize: 20 }}>
                                                    <AiOutlineCheckCircle size="32" color="#007D00" /> Finalizados
                                                </Card.Header>
                                                <Card.Title style={{ display: 'flex', justifyContent: 'center', color: '#007D00', fontSize: 72, marginTop: 20 }}>
                                                    {finalizadas}
                                                </Card.Title>
                                                <Card.Footer style={{ display: 'flex', justifyContent: 'flex-end', color: '#007D00', backgroundColor: 'white', paddingTop: 20 }}>
                                                    <Button
                                                        style={{ backgroundColor: 'white', color: '#007D00', borderColor: '#007D00' }}
                                                        size="sm"
                                                        onClick={() => {
                                                            Navigate('/adm/solicitacoes/minhas-solicitacoes', { state: { statusRecebido: 'FINALIZADAS' } })
                                                        }}
                                                    >
                                                        Detalhar
                                                    </Button>
                                                </Card.Footer>

                                            </Card.Body>
                                        </Card>
                                    </Col>


                                </Row>

                            </div>
                        }

                    </Container>
                    <Footer />
                </>

            }




            <Modal show={loading} centered >
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 24 }}>
                    {/* <Spinner animation="border" style={{ color: "#E30613" }} /> */}
                    <img
                        src={LogoArquivo}
                        width={'20%'}
                        alt={'Imagem de Nuvem'}
                        loading={"lazy"}
                    ></img>
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {msgLoading}
                </Modal.Footer>
            </Modal>




            <Modal show={telaAlerta} centered>
                <Modal.Header style={{ color: '#E30613', fontSize: 24 }}>
                    {telaTitulo}
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {telaMsg}
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button style={{ backgroundColor: '#E30613', border: 0 }} onClick={() => { setTelaAlerta(false) }}>OK</Button>
                </Modal.Footer>
            </Modal>




        </>
    )
}

export default Home